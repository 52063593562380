import { useMutation, useQuery } from '@vue/apollo-composable'
import { gql } from '@apollo/client'
import { storeToRefs } from 'pinia'

export function getFuzzySearch(keyword: string) {
  const query = gql`
    query SearchPages($keyword: String!) {
      search(query: $keyword) {
        pages {
          data {
            id
            attributes {
              Title
              Description
            }
          }
        }
      }
    }
  `
  const variables = { keyword }
  return useQuery(query, variables, { prefetch: false })
}

export function getList({ page, pageSize }: { page: number; pageSize: number }) {
  const { account } = storeToRefs(useUserStore())
  const query = gql`
      query getPages($page: Int!, $pageSize: Int!, $userId: ID!) {
        pages(pagination: { page: $page, pageSize: $pageSize }, filters: { users_permissions_user: { id: { eq: $userId } } }, sort: ["createdAt:desc"]) {
          data{
            id
            attributes {
              Title
              Description
              updatedAt
            }
          }
          meta{
            pagination{
                total
                pageCount
            }
          }
        }
      }
    `
  const variables = { page, pageSize, userId: account.value?.id }
  return useQuery(query, variables, { prefetch: false })
}

export function getDetail(id: string) {
  const { account } = storeToRefs(useUserStore())
  const query = gql`
      query getPages($id: ID!, $userId: ID!) {
        pages(filters: { id: { eq: $id }, users_permissions_user: { id: { eq: $userId } } }) {
          data{
            id
            attributes {
              Title
              Description
            }
          }
        }
      }
    `
  const variables = { id, userId: account.value?.id }
  return useQuery(query, variables, { prefetch: false })
}

export function createNew({ title, description }: { title: string; description: string }) {
  const { account } = storeToRefs(useUserStore())
  const mutation = gql`
      mutation createPage ($title: String, $description: String, $publishedAt: DateTime, $userId: ID!){
        createPage(data: { Title: $title, Description: $description, publishedAt: $publishedAt, users_permissions_user: $userId }) {
          data {
            id
            attributes {
              Title
              Description
              publishedAt
            }
          }
        }
      }
    `
  const variables = { title, description, publishedAt: new Date(), userId: account.value?.id }
  return useMutation(mutation, { variables })
}

export function updateDetail({ id, title, description }: { id: string | number; title: string; description: string }) {
  const mutation = gql`
      mutation updatePage ($id: ID!, $title: String, $description: String ){
        updatePage(id: $id, data: { Title: $title, Description: $description }) {
          data {
            id
            attributes {
              Title
              Description
            }
          }
        }
      }
    `
  const variables = { id, title, description }
  return useMutation(mutation, { variables })
}

export function removeDetail({ id }: { id: string }) {
  const mutation = gql`
      mutation deletePage ($id: ID!){
        deletePage(id: $id) {
          data {
            id
            attributes {
              Title
              Description
            }
          }
        }
      }
    `
  const variables = { id }
  return useMutation(mutation, { variables })
}
